import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
// import BaseFunctions from '@/components/Utility/Base'
import CommonFunctions, { defaultInvoiceURL } from '@/components/Utility/Common'
import { Products } from '@/services/ProductsDataService'
import { Orders } from '@/services/OrdersDataService'
import SysProduct, { SysProductProductType, SysAccount, AccountLSType } from '@/types/SysProduct'
import SysOrder, { OrderStatusType, SysOrderUserId } from '@/types/SysOrder'
import exportFromJSON from 'export-from-json'
import { Tournaments } from '@/services/TournamentsDataService'
import SeasonsDataService from '@/services/SeasonsDataService'
import SysSaeson from '@/types/SysSaeson'
import { SysTournamentCategoryId } from '@/types/SysTournament'

/* export default defineComponent({
  name: 'AdminProducts',
  setup () {
    // component setup
  }
}) */

type orderDataType = { orderCreated: string; orderUpdated: string; userName: string; orderInvoiceNumber: number, orderComment: string; orderTransaction: number; orderInvoice: any[]; orderName: string; orderAddress: string; orderCity: string; orderPostalNumber: string; orderCountry: string; orderTelephone: string; orderEmail: string; orderUnits: any[]; orderTotal: number; orderStatus: string; }
type productDataType = { productStatus: boolean; productName: string; productDescription: string; productPrice: number; productDateFrom: string; productDateTo: string }
type productTypeDataType = { productTypeId: number; productTypeStatus: boolean; productIsUnique: boolean; productTypeName: string; productTypeDescription: string; productTypeAccountValueTo: { accountId: number, accountNumber: number, accountName: string, ls: AccountLSType | null }; productTypeAccountValue1: { accountId: number, accountNumber: number, accountName: string, ls: AccountLSType | null }; productTypeAccountValue2: { accountId: number, accountNumber: number, accountName: string, ls: AccountLSType | null }; productTypeAccountValue3: { accountId: number, accountNumber: number, accountName: string, ls: AccountLSType | null }; productTypeAccountFrom1: string; productTypeAccountFrom2: string; productTypeAccountFrom3: string; productTypeAccountTo: string }
type dataReturnType = { produkts: any; produkttypes: any; orderProducts: any; orders: any; filterStatusStringValue: string; filterStatusStringValue2: string; productSearchInputValue: string; productTypeSearchInputValue: string; tabValue: string; index: number; error: any; }

@Options({
  props: {
    msg: String
  }
})
export default class AdminProducts extends Vue {
  msg!: string
  error: any = null
  tabValue = 'Vis og rediger produkter'
  orders: SysOrder[] = []
  produkts: SysProduct[] = []
  orderProducts: SysProduct[] = []
  produkttypes: SysProductProductType[] = []
  private tempOrder = {} as SysOrder
  private tempProduct = {} as SysProduct
  private tempProductType = {} as SysProductProductType
  productData: productDataType = { productStatus: true, productName: '', productDescription: '', productPrice: 0, productDateFrom: '', productDateTo: '' }
  productTypeData: productTypeDataType = { productTypeId: 0, productTypeStatus: true, productIsUnique: false, productTypeName: '', productTypeDescription: '', productTypeAccountFrom1: '', productTypeAccountFrom2: '', productTypeAccountFrom3: '', productTypeAccountTo: '', productTypeAccountValue1: { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null }, productTypeAccountValue2: { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null }, productTypeAccountValue3: { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null }, productTypeAccountValueTo: { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null } }
  orderData: orderDataType = { orderCreated: '', orderUpdated: '', userName: '', orderInvoiceNumber: 0, orderComment: '', orderTransaction: 0, orderInvoice: [], orderName: '', orderAddress: '', orderCity: '', orderCountry: '', orderTelephone: '', orderEmail: '', orderTotal: 0, orderUnits: [], orderStatus: '', orderPostalNumber: '' }
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Aktiveret', 'Deaktiveret']
  filterStatusStringValue2 = 'Vis alt'
  filterStatusStringOptions2: string[] = ['Vis alt', 'Aktiveret', 'Deaktiveret']
  productExtraParameter = 'produkt_type_id.id_nin=6&produkt_type_id.id_nin=12'
  orderExtraParameter = ''
  orderSearchInputValue = ''
  orderSearchTerm = ''
  productSearchInputValue = ''
  productSearchTerm = ''
  productTypeSearchInputValue = ''
  productTypeSearchTerm = ''
  productTypeAccountOptions: { accountId: number, accountName: string, ls: AccountLSType | null }[] = []
  toggleIconsSortingValue = 0
  toggleIconsSortingValue2 = 0
  productTypesNameValue = { name: 'Vælg her', value: '0' }
  productTypesNameOptions: { name: string; value: string }[] = [{ name: 'Vælg her', value: '0' }]
  private showAllProducts = false
  private oldSort = ''
  private oldSort2 = ''
  private oldSort3 = ''
  private submitted = false
  private submitted2 = false
  private currentProductSortingOrder = 'produkt_type_id.produkt_type_navn:asc,produkt_navn:asc'
  private currentProductTypeSortingOrder = 'produkt_type_navn:asc'
  private currentOrdersSortingOrder = 'created_at:desc'
  private editProductModal = false
  private editProductTypeModal = false
  private editOrderModal = false
  private deleteProductWarningModal = false
  private deleteProductTypeWarningModal = false
  private deleteProductTypeId = 0
  private deleteProductId = 0
  private lastEditedOrderId = 0
  private lastEditedProductId = 0
  private lastEditedProductTypeId = 0
  private lastCreatedProductId = 0
  private lastCreatedProductTypeId = 0
  private index = 0
  private columnName: string[] = ['name', 'type', 'dateFrom', 'dateTo', 'price']
  private columnName2: string[] = ['name', 'description', 'unique']
  private columnName3: string[] = ['invoiceId', 'name', 'status', 'created', 'total']
  private currentProductPage = 1
  private currentOrderPage = 1
  private totalProductPages = 0
  private totalOrderPages = 0
  private totalNumberOfProductPages = 0
  private totalNumberOfOrderPages = 0
  private productPageSizeValue = 25
  private orderPageSizedValue = 25
  private productPageSizeValueString = '25'
  private orderPageSizeValueString = '25'
  private productPageSizeOptions: string[] = ['10', '25', '100']
  private orderPageSizeOptions: string[] = ['10', '25', '100']
  private statusFilterOrderOptions = this.createOrderStatusOptions()
  private statusFilterOrderValue = { name: 'Alle', value: 'alle' }
  private orderStatusOptions = Object.values(OrderStatusType).filter(value => typeof value === 'string').filter(function (element) { return (<string>element).startsWith((<string>element).substring(0, 1).toUpperCase()) })
  // private orderStatusOptions = Object.keys(OrderStatusType).map((name) => { return { name, value: OrderStatusType[name as keyof typeof OrderStatusType] } }).filter(v => v.name[0].toUpperCase() === v.name[0])
  // Accounts global variables.
  accounts: SysAccount[] = []
  currentAccountsSort = 'konti_navn:asc'
  oldAccountSort = ''
  currentAccountPage = 1
  totalAccountPages = 0
  totalNumberOfAccountPages = 0
  accountPageSizeValue = 25
  accountPageSizeValueString = '25'
  accountPageSizeOptions: string[] = ['10', '25', '100']
  accountLSFilterOptions = Object.values(AccountLSType).filter(value => typeof value === 'string')
  accountLSFilterValue = 'Alle'
  accountInputValue = ''
  accountSearchTerm = ''
  accountExtraParameter = ''
  creatingNewAccount = false
  account = {} as SysAccount
  accountModal = false
  accountLSOptions = Object.values(AccountLSType).filter(value => typeof value === 'string')
  accountWarningModal = false
  accountDeleteId = 0
  tempAccounts: SysAccount[] = []
  lsType1 = AccountLSType.ls1
  lsType2 = AccountLSType.ls2
  lsType3 = AccountLSType.ls3
  exportModal = false
  exportFrom = ''
  exportTo = ''
  exportFlag = false
  exportAccountingAnnexStartOffset = 1
  teamSeason: SysSaeson[] = []
  teamTournamentCategories: SysTournamentCategoryId[] = []
  selectedSeasonInfo = {} as SysSaeson
  seasonValue = { id: 0, name: 'Vælg Sæson' }
  seasonOptions: { id: number, name: string }[] = []
  teamProducts: SysProduct[] = []
  teamProductsModal = false
  teamProductsArray: { productId: number; allReadyCreatedProduct: boolean; createNewProduct: boolean; signupBegin: string; lastSignupDate: string; price: number, productName: string; }[] = []
  selectedSearchField: any = []
  searchFieldOptions: any = [
    { name: 'Navn', value: 'ordre_navn' },
    { name: 'Fakturanummer', value: 'ordre_fakturanummer' },
    { name: 'Ordreenheders', value: 'ordreenheders_id' },
    { name: 'Transaktion', value: 'id' }
  ]

  readonly name : string = 'AdminProducts'
  $Message: any

  data (): dataReturnType {
    return {
      produkts: this.produkts,
      produkttypes: this.produkttypes,
      orderProducts: this.orderProducts,
      orders: this.orders,
      filterStatusStringValue: this.filterStatusStringValue,
      filterStatusStringValue2: this.filterStatusStringValue2,
      productSearchInputValue: this.productSearchInputValue,
      productTypeSearchInputValue: this.productTypeSearchInputValue,
      tabValue: 'Vis og rediger produkter',
      index: this.index,
      error: this.error
    }
  }

  // Export methods start.

  @Watch('exportModal')
  onExportModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.exportFlag = false
      this.exportFrom = ''
      this.exportTo = ''
    }
  }

  @Watch('seasonValue')
  async onSeasonValueChange () : Promise<void> {
    if (this.seasonValue.id !== 0) {
      try {
        this.teamProducts = (await Products.ProductsDataService.getAll('', null, `produkt_navn_contains=${this.seasonValue.name}&produkt_type_id.id=13`)).data
      } catch (err) {
        console.log(err)
      }
      if (this.teamProducts.length > 0) {
        for (const item of this.teamProductsArray) {
          const index = this.teamProducts.findIndex(el => el.produkt_navn.toLowerCase().includes(item.productName.toLowerCase()))
          console.log(index)
          if (index !== -1) {
            item.allReadyCreatedProduct = true
            item.productId = Number(this.teamProducts[index].id)
            item.lastSignupDate = this.teamProducts[index].produkt_datotil
            item.signupBegin = this.teamProducts[index].produkt_datofra
            // item.productName = this.teamProducts[index].produkt_navn
            item.price = this.teamProducts[index].produkt_pris
          }
        }
      } else {
        for (const item of this.teamProductsArray) {
          item.allReadyCreatedProduct = false
          item.productId = 0
          item.lastSignupDate = new Date().toISOString().split('T')[0]
          item.signupBegin = new Date().toISOString().split('T')[0]
          // item.productName = 'Tilmelding til ' + (this.teamTournamentCategories[].turneringskategori_navn)
        }
      }
    }
  }

  public async updateTeamProducts () : Promise<void> {
    if (this.seasonValue.id === 0) {
      this.$Message.danger({ text: 'Ingen sæson er angivt' })
      return
    }
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)
    const promises = []
    for (const item of this.teamProductsArray) {
      if (item.createNewProduct && item.price.toString().length > 0 && item.lastSignupDate !== '' && item.lastSignupDate !== '') {
        if (item.allReadyCreatedProduct && item.productId !== 0) {
          const updateProduct = {
            produkt_pris: item.price,
            produkt_datofra: item.signupBegin,
            produkt_datotil: item.lastSignupDate
          }
          promises.push(Products.ProductsDataService.update(item.productId.toString(), updateProduct))
        } else {
          const createProduct = {
            produkt_status: true,
            produkt_navn: item.productName + ' for ' + this.seasonValue.name.toLowerCase(),
            produkt_beskrivelse: 'Holdtilmelding til ' + item.productName.slice(14),
            produkt_datofra: item.signupBegin,
            produkt_pris: item.price,
            produkt_datotil: item.lastSignupDate,
            produkt_type_id: 13,
            user_id: userDataObject.id
          }

          promises.push(Products.ProductsDataService.create(createProduct))
        }
      }
    }

    await Promise.all(promises)
      .then((responses) => {
        for (const response of responses) {
          console.log(response.statusText)
        }
        this.$Message.success({ text: 'Hold tilmeldinger er blevet oprettet/opdateret' })
        this.teamProductsModal = false
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public async createTeamProducts () : Promise<void> {
    const tournamentCategoriesPromise = Tournaments.TournamentCategoriesDataService.getAll('', null, 'turneringskategori_status=true')
    const seasonsPromise = SeasonsDataService.getAll('', null, 'saeson_status=true')
    this.seasonOptions = []

    await Promise.all([tournamentCategoriesPromise, seasonsPromise])
      .then((response) => {
        this.teamTournamentCategories = response[0].data as SysTournamentCategoryId[]
        this.teamSeason = response[1].data as SysSaeson[]
        this.teamProductsArray = []
        for (const item of this.teamTournamentCategories) {
          this.teamProductsArray.push({
            productId: 0,
            allReadyCreatedProduct: false,
            createNewProduct: false,
            lastSignupDate: new Date().toISOString().split('T')[0],
            signupBegin: new Date().toISOString().split('T')[0],
            price: item.turneringskategori_pris,
            productName: 'Tilmelding til ' + item.turneringskategori_navn
          })
        }

        for (const item of this.teamSeason) {
          this.seasonOptions.push({ id: Number(item.id), name: item.saeson_navn })
        }
        this.seasonOptions.unshift({ id: 0, name: 'Vælg Sæson' })
        this.teamProductsModal = true
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public async exportAccounting () : Promise<void> {
    if (this.exportFrom === '' || this.exportTo === '') {
      this.$Message.warning({ text: 'Fejl: Et felt er ikke udfyldt korrekt' })
      return
    }

    this.exportFlag = true
    let orders: SysOrder[] = []
    let products: SysProduct[] = []
    let accounts: SysAccount[] = []
    const data: { Dato: string, Bilag: number, Konto: number, Dimension1: number, Dimension2: number, Beskrivelse: string, Beløb: number, KontoType: string }[] = []

    // Fetching all orders from a given period
    try {
      orders = (await Orders.OrdersDataService.getAll('', null, `created_at_gte=${new Date(this.exportFrom).toISOString()}&created_at_lte=${new Date(this.exportTo).toISOString()}&ordre_status=godkendt`)).data
    } catch (err) {
      console.log(err)
    }

    const productIds: number[] = []
    // Loop through all orders and find all unique product ids, then retrieve them from API
    for (const order of orders) {
      for (const unit of order.ordreenheders_id) {
        if (!productIds.includes(unit.produkt_id)) {
          productIds.push(unit.produkt_id)
        }
      }
    }

    let productParams = ''
    for (const id of productIds) {
      productParams += '&id=' + id.toString()
    }
    productParams = productParams.substring(1)

    try {
      products = (await Products.ProductsDataService.getAll('', null, productParams)).data
    } catch (err) {
      console.log(err)
    }

    // Loop through all the products to find unique account ids, then retrieve them from API
    const accountIds: number[] = []
    for (const product of products) {
      if (!accountIds.includes(product.produkt_type_id.konti_fra1) && product.produkt_type_id.konti_fra1 !== null) {
        accountIds.push(product.produkt_type_id.konti_fra1)
      }
      if (!accountIds.includes(product.produkt_type_id.konti_fra2) && product.produkt_type_id.konti_fra2 !== null) {
        accountIds.push(product.produkt_type_id.konti_fra2)
      }
      if (!accountIds.includes(product.produkt_type_id.konti_fra3) && product.produkt_type_id.konti_fra3 !== null) {
        accountIds.push(product.produkt_type_id.konti_fra3)
      }
      if (!accountIds.includes(product.produkt_type_id.konti_til) && product.produkt_type_id.konti_til !== null) {
        accountIds.push(product.produkt_type_id.konti_til)
      }
    }

    let accountParams = ''
    for (const id of accountIds) {
      accountParams += '&id=' + id.toString()
    }
    accountParams = accountParams.substring(1)

    try {
      accounts = (await Products.AccountsDataService.getAll('', null, accountParams)).data
    } catch (err) {
      console.log(err)
    }
    console.log(orders)
    console.log(productIds)
    console.log(products)
    console.log(accountIds)
    console.log(accounts)

    function findAccountNumber (productId: number, lsNumber = 0) : number {
      const productIndex = products.findIndex(el => Number(el.id) === productId)

      if (productIndex === -1) {
        return 0
      }

      let retVal = 0
      switch (lsNumber) {
        case 1: {
          if (products[productIndex].produkt_type_id.konti_fra1 === null) {
            retVal = 0
          } else {
            const index = accounts.findIndex(el => Number(el.id) === products[productIndex].produkt_type_id.konti_fra1)
            retVal = (index === -1 ? 0 : accounts[index].konti_nummer)
          }

          break
        }
        case 2: {
          if (products[productIndex].produkt_type_id.konti_fra2 === null) {
            retVal = 0
          } else {
            const index = accounts.findIndex(el => Number(el.id) === products[productIndex].produkt_type_id.konti_fra2)
            retVal = (index === -1 ? 0 : accounts[index].konti_nummer)
          }

          break
        }
        case 3: {
          if (products[productIndex].produkt_type_id.konti_fra3 === null) {
            retVal = 0
          } else {
            const index = accounts.findIndex(el => Number(el.id) === products[productIndex].produkt_type_id.konti_fra3)
            retVal = (index === -1 ? 0 : accounts[index].konti_nummer)
          }

          break
        }
        default: {
          if (products[productIndex].produkt_type_id.konti_til === null) {
            retVal = 0
          } else {
            const index = accounts.findIndex(el => Number(el.id) === products[productIndex].produkt_type_id.konti_til)
            retVal = (index === -1 ? 0 : accounts[index].konti_nummer)
          }

          break
        }
      }

      return retVal
    }
    for (let i = 0; i < orders.length; i++) {
      for (const unit of orders[i].ordreenheders_id) {
        data.push({
          Dato: new Date(orders[i].created_at).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }),
          Bilag: Math.abs(Math.round(Number(this.exportAccountingAnnexStartOffset))) + i,
          Konto: findAccountNumber(unit.produkt_id, 1),
          Dimension1: findAccountNumber(unit.produkt_id, 2),
          Dimension2: findAccountNumber(unit.produkt_id, 3),
          Beskrivelse: unit.ordreenheder_beskrivelse + ' (Nr. ' + Number(orders[i].ordre_fakturanummer) + ' / ID ' + Number(orders[i].id) + ')',
          Beløb: (unit.ordreenheder_pris === null || unit.ordreenheder_pris === 0 || unit.ordreenheder_antal === null || unit.ordreenheder_antal === 0 ? 0 : -unit.ordreenheder_antal * unit.ordreenheder_pris),
          KontoType: 'Finanskonto'
        })
      }

      data.push({
        Dato: new Date(orders[i].created_at).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }),
        Bilag: Math.abs(Math.round(Number(this.exportAccountingAnnexStartOffset))) + i,
        Konto: findAccountNumber(orders[i].ordreenheders_id[0].produkt_id, 0),
        Dimension1: 0,
        Dimension2: 0,
        Beskrivelse: 'BETALING (' + orders[i].id + ')',
        Beløb: orders[i].ordre_total,
        KontoType: 'Finanskonto'
      })
    }
    console.log(data)

    const exportType = exportFromJSON.types.xls
    const fileName = 'ordre-' + new Date(this.exportFrom).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }) + '-' + new Date(this.exportTo).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' })
    exportFromJSON({ data, fileName, exportType })
    this.exportModal = false
  }

  // Export methods end.

  @Watch('statusFilterOrderValue')
  onStatusFilterValueChange () : void {
    if (this.statusFilterOrderValue.value === 'alle') {
      this.orderExtraParameter = ''
    } else {
      this.orderExtraParameter = 'ordre_status=' + this.statusFilterOrderValue.value
    }

    this.retrieveOrders()
  }

  @Watch('deleteProductWarningModal')
  onProductWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteProductId = 0
    }
  }

  @Watch('deleteProductTypeWarningModal')
  onProductTypeWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteProductTypeId = 0
    }
  }

  @Watch('filterStatusStringValue')
  onProductStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveProducts()
  }

  @Watch('filterStatusStringValue2')
  onProductTypeStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveProductTypes()
  }

  @Watch('productPageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.productPageSizeValue = Number(newVal)
    this.currentProductPage = 1
    this.retrieveProducts()
    CommonFunctions.scrollToTop()
  }

  @Watch('orderPageSizeValueString')
  onOrderPageSizeValueChange (newVal: any) : void {
    this.orderPageSizedValue = Number(newVal)
    this.currentOrderPage = 1
    this.retrieveOrders()
    CommonFunctions.scrollToTop()
  }

  public findUnitPrice (unitId = 0) : number {
    let retVal = 0
    const productIndex = this.orderProducts.findIndex(element => element.id === unitId)

    if (productIndex !== -1) {
      retVal = this.orderProducts[productIndex].produkt_pris
    }

    return retVal
  }

  public generateInvoiceURL (url: string) : string {
    return defaultInvoiceURL + url
  }

  public orderStatus (statusParam: OrderStatusType) : string {
    const statusString = statusParam.toString()
    let retVal = ''

    if (statusString === 'godkendt') {
      retVal = 'background-color:#3e9209!important;color:#fff;'
    } else if (statusString === 'afvist') {
      retVal = 'background-color:#e42222!important;color:#fff;'
    } else if (statusString === 'annulleret') {
      retVal = 'background-color:#1e9be3!important;color:#fff;'
    } else if (statusString === 'afventer') {
      retVal = 'background-color:#ffac0a!important;'
    }

    return retVal
  }

  public createOrderStatusOptions () : any {
    const retArray = Object.keys(OrderStatusType).map((name) => {
      return {
        name,
        value: OrderStatusType[name as keyof typeof OrderStatusType].toString()
      }
    }).filter(item => item.name[0].toUpperCase() === item.name[0])

    retArray.push({ name: 'Alle', value: 'alle' })

    return retArray
  }

  public changeProductsDisplay () : void {
    this.showAllProducts = !this.showAllProducts

    if (this.showAllProducts === true) {
      this.productExtraParameter = ''
    } else {
      this.productExtraParameter = 'produkt_type_id.id_nin=6&produkt_type_id.id_nin=12'
    }

    this.retrieveProducts()
  }

  public turnToNewProductPage (pageChange: number) : void {
    this.currentProductPage += pageChange

    if (this.currentProductPage < 1) {
      this.currentProductPage = 1
    } else if (this.currentProductPage >= this.totalNumberOfProductPages) {
      this.currentProductPage = this.totalNumberOfProductPages
    }

    this.retrieveProducts()
    CommonFunctions.scrollToTop()
  }

  public turnToNewOrderPage (pageChange: number) : void {
    this.currentOrderPage += pageChange

    if (this.currentOrderPage < 1) {
      this.currentOrderPage = 1
    } else if (this.currentOrderPage >= this.totalNumberOfOrderPages) {
      this.currentOrderPage = this.totalNumberOfOrderPages
    }

    this.retrieveOrders()
    CommonFunctions.scrollToTop()
  }

  public jumpToProductPage (pageNumber: number) : void {
    this.currentProductPage = pageNumber

    if (this.currentProductPage < 1) {
      this.currentProductPage = 1
    } else if (this.currentProductPage > this.totalNumberOfProductPages) {
      this.currentProductPage = this.totalNumberOfProductPages
    }

    this.retrieveProducts()
    CommonFunctions.scrollToTop()
  }

  public jumpToOrderPage (pageNumber: number) : void {
    this.currentOrderPage = pageNumber

    if (this.currentOrderPage < 1) {
      this.currentOrderPage = 1
    } else if (this.currentOrderPage > this.totalNumberOfOrderPages) {
      this.currentOrderPage = this.totalNumberOfOrderPages
    }

    this.retrieveOrders()
    CommonFunctions.scrollToTop()
  }

  public columnOrderSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.currentOrdersSortingOrder = 'created_at:desc'
      this.oldSort3 = ''
      this.orderSearchInputValue = ''
      this.orderSearchTerm = ''
      this.statusFilterOrderValue = { name: 'Alle', value: 'alle' }
    }
    if (sortBy === 'invoiceId') {
      if (this.oldSort3 === '' || this.oldSort3 !== sortBy) {
        this.currentOrdersSortingOrder = 'ordre_fakturanummer:asc'
        this.oldSort3 = sortBy
      } else {
        this.currentOrdersSortingOrder = 'ordre_fakturanummer:desc'
        this.oldSort3 = ''
      }
    }
    if (sortBy === 'name') {
      if (this.oldSort3 === '' || this.oldSort3 !== sortBy) {
        this.currentOrdersSortingOrder = 'ordre_navn:asc'
        this.oldSort3 = sortBy
      } else {
        this.currentOrdersSortingOrder = 'ordre_navn:desc'
        this.oldSort3 = ''
      }
    }
    if (sortBy === 'status') {
      if (this.oldSort3 === '' || this.oldSort3 !== sortBy) {
        this.currentOrdersSortingOrder = 'ordre_status:asc'
        this.oldSort3 = sortBy
      } else {
        this.currentOrdersSortingOrder = 'ordre_status:desc'
        this.oldSort3 = ''
      }
    }
    if (sortBy === 'created') {
      if (this.oldSort3 === '' || this.oldSort3 !== sortBy) {
        this.currentOrdersSortingOrder = 'created_at:asc'
        this.oldSort3 = sortBy
      } else {
        this.currentOrdersSortingOrder = 'created_at:desc'
        this.oldSort3 = ''
      }
    }
    if (sortBy === 'updated') {
      if (this.oldSort3 === '' || this.oldSort3 !== sortBy) {
        this.currentOrdersSortingOrder = 'updated_at:asc'
        this.oldSort3 = sortBy
      } else {
        this.currentOrdersSortingOrder = 'updated_at:desc'
        this.oldSort3 = ''
      }
    }
    if (sortBy === 'total') {
      if (this.oldSort3 === '' || this.oldSort3 !== sortBy) {
        this.currentOrdersSortingOrder = 'ordre_total:asc'
        this.oldSort3 = sortBy
      } else {
        this.currentOrdersSortingOrder = 'ordre_total:desc'
        this.oldSort3 = ''
      }
    }

    this.retrieveOrders()
  }

  public columnProductTypeSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.currentProductTypeSortingOrder = 'produkt_type_navn:asc'
      this.oldSort2 = ''
      this.productTypeSearchInputValue = ''
      this.productTypeSearchTerm = ''
    }
    if (sortBy === 'name') {
      if (this.oldSort2 === '' || this.oldSort2 !== sortBy) {
        this.currentProductTypeSortingOrder = 'produkt_type_navn:desc'
        this.oldSort2 = sortBy
      } else {
        this.currentProductTypeSortingOrder = 'produkt_type_navn:asc'
        this.oldSort2 = ''
      }
    }
    if (sortBy === 'description') {
      if (this.oldSort2 === '' || this.oldSort2 !== sortBy) {
        this.currentProductTypeSortingOrder = 'produkt_type_beskrivelse:asc'
        this.oldSort2 = sortBy
      } else {
        this.currentProductTypeSortingOrder = 'produkt_type_beskrivelse:desc'
        this.oldSort2 = ''
      }
    }
    if (sortBy === 'unique') {
      if (this.oldSort2 === '' || this.oldSort2 !== sortBy) {
        this.currentProductTypeSortingOrder = 'produkt_type_unikt:asc'
        this.oldSort2 = sortBy
      } else {
        this.currentProductTypeSortingOrder = 'produkt_type_unikt:desc'
        this.oldSort2 = sortBy
      }
    }
    if (sortBy === 'created') {
      if (this.oldSort2 === '' || this.oldSort2 !== sortBy) {
        this.currentProductTypeSortingOrder = 'created_at:desc'
        this.oldSort2 = sortBy
      } else {
        this.currentProductTypeSortingOrder = 'created_at:asc'
        this.oldSort2 = sortBy
      }
    }

    this.retrieveProductTypes()
  }

  public columnProductSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.currentProductSortingOrder = 'produkt_type_id.produkt_type_navn:asc,produkt_navn:asc'
      this.oldSort = ''
      this.productSearchTerm = ''
      this.productSearchInputValue = ''
    }
    if (sortBy === 'name') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentProductSortingOrder = 'produkt_navn:asc'
        this.oldSort = sortBy
      } else {
        this.currentProductSortingOrder = 'produkt_navn:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'type') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentProductSortingOrder = 'produkt_type_id.produkt_type_navn:asc'
        this.oldSort = sortBy
      } else {
        this.currentProductSortingOrder = 'produkt_type_id.produkt_type_navn:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'dateFrom') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentProductSortingOrder = 'produkt_datofra:asc'
        this.oldSort = sortBy
      } else {
        this.currentProductSortingOrder = 'produkt_datofra:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'dateTo') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentProductSortingOrder = 'produkt_datotil:asc'
        this.oldSort = sortBy
      } else {
        this.currentProductSortingOrder = 'produkt_datotil:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'price') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentProductSortingOrder = 'produkt_pris:asc'
        this.oldSort = sortBy
      } else {
        this.currentProductSortingOrder = 'produkt_pris:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'unique') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentProductSortingOrder = 'produkt_type_id.produkt_type_unikt:asc'
        this.oldSort = sortBy
      } else {
        this.currentProductSortingOrder = 'produkt_type_id.produkt_type_unikt:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'created') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentProductSortingOrder = 'created_at:desc'
        this.oldSort = sortBy
      } else {
        this.currentProductSortingOrder = 'created_at:asc'
        this.oldSort = ''
      }
    }

    this.retrieveProducts()
  }

  public toDanishDateString (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
  }

  public forceRerenderProductsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public forceRerenderOrdersList () : void {
    this.error = 'Opdatererer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public showColumn (column: string) : boolean {
    return this.columnName.includes(column)
  }

  public showColumn2 (column: string) : boolean {
    return this.columnName2.includes(column)
  }

  public showColumn3 (column: string) : boolean {
    return this.columnName3.includes(column)
  }

  public findOrder () : void {
    console.log('Search orders')
    this.orderSearchTerm = this.orderSearchInputValue
    this.retrieveOrders()
  }

  public findProduct () : void {
    console.log('Search products.')
    this.productSearchTerm = this.productSearchInputValue
    this.retrieveProducts()
  }

  public findProductType () : void {
    console.log('Search product types.')
    this.productTypeSearchTerm = this.productTypeSearchInputValue
    this.retrieveProductTypes()
  }

  public editProduct (productId: number) : void {
    console.log('Edit product item with Id: ' + productId)
    this.editProductModal = true
    this.lastEditedProductId = productId

    Products.ProductsDataService.get(productId.toString())
      .then((response) => {
        this.tempProduct = response.data
        console.log(response.data)

        this.productData = { productStatus: this.tempProduct.produkt_status, productName: this.tempProduct.produkt_navn, productDescription: this.tempProduct.produkt_beskrivelse, productPrice: this.tempProduct.produkt_pris, productDateFrom: this.tempProduct.produkt_datofra, productDateTo: this.tempProduct.produkt_datotil }
        if (this.tempProduct.produkt_type_id === undefined || this.tempProduct.produkt_type_id === null || this.tempProduct.produkt_type_id.id === null) {
          this.productTypesNameValue = { name: 'Vælg her', value: '0' }
        } else {
          this.productTypesNameValue = { name: this.tempProduct.produkt_type_id.produkt_type_navn, value: (this.tempProduct.produkt_type_id.id === undefined ? '0' : Number(this.tempProduct.produkt_type_id.id).toString()) }
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelProductEdit () : void {
    console.log('Cancel product item edit.')
    this.productData = { productStatus: true, productName: '', productDescription: '', productPrice: 0, productDateFrom: '', productDateTo: '' }
    this.productTypesNameValue = { name: 'Vælg her', value: '0' }
  }

  public editProductType (productTypeId: number) : void {
    console.log('Edit product type item with Id: ' + productTypeId)
    this.editProductTypeModal = true
    this.lastEditedProductTypeId = productTypeId

    Products.ProductTypesDataService.get(productTypeId.toString())
      .then((response) => {
        this.tempProductType = response.data
        console.log(response.data)

        this.productTypeData = {
          productTypeId: Number(this.tempProductType.id),
          productTypeStatus: this.tempProductType.produkt_type_status,
          productIsUnique: this.tempProductType.produkt_type_unikt,
          productTypeName: this.tempProductType.produkt_type_navn,
          productTypeDescription: this.tempProductType.produkt_type_beskrivelse,
          productTypeAccountFrom1: this.tempProductType.produkt_type_kontofra1,
          productTypeAccountFrom2: this.tempProductType.produkt_type_kontofra2,
          productTypeAccountFrom3: this.tempProductType.produkt_type_kontofra3,
          productTypeAccountTo: this.tempProductType.produkt_type_kontotil,
          productTypeAccountValue1: (this.tempProductType.konti_fra1 === null ? { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null } : { accountId: Number(this.tempProductType.konti_fra1.id), accountNumber: this.tempProductType.konti_fra1.konti_nummer, accountName: this.tempProductType.konti_fra1.konti_navn, ls: this.tempProductType.konti_fra1.konto_ls }),
          productTypeAccountValue2: (this.tempProductType.konti_fra2 === null ? { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null } : { accountId: Number(this.tempProductType.konti_fra2.id), accountNumber: this.tempProductType.konti_fra2.konti_nummer, accountName: this.tempProductType.konti_fra2.konti_navn, ls: this.tempProductType.konti_fra2.konto_ls }),
          productTypeAccountValue3: (this.tempProductType.konti_fra3 === null ? { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null } : { accountId: Number(this.tempProductType.konti_fra3.id), accountNumber: this.tempProductType.konti_fra3.konti_nummer, accountName: this.tempProductType.konti_fra3.konti_navn, ls: this.tempProductType.konti_fra3.konto_ls }),
          productTypeAccountValueTo: (this.tempProductType.konti_til === null ? { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null } : { accountId: Number(this.tempProductType.konti_til.id), accountNumber: this.tempProductType.konti_til.konti_nummer, accountName: this.tempProductType.konti_til.konti_navn, ls: this.tempProductType.konti_til.konto_ls })
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelProductTypeEdit () : void {
    console.log('Cancel product item edit.')
    this.clearProductDataType()
    // this.productTypeData = { productTypeStatus: true, productIsUnique: false, productTypeName: '', productTypeDescription: '', productTypeAccountFrom1: '', productTypeAccountFrom2: '', productTypeAccountFrom3: '', productTypeAccountTo: '' }
    this.productTypesNameValue = { name: 'Vælg her', value: '0' }
  }

  public statusProduct (productId: number) : void {
    console.log('Change status of product item with Id: ' + productId)

    Products.ProductsDataService.get(productId.toString())
      .then((response) => {
        this.tempProduct = response.data
        console.log(response.data)

        const updateProductData = {
          produkt_status: !this.tempProduct.produkt_status
        }

        console.log('New status of product item = ' + updateProductData.produkt_status)

        Products.ProductsDataService.update(productId.toString(), updateProductData)
          .then((response) => {
            // this.lastUpdatedProductId = response.data.id
            console.log(response.data)

            const productsIndexToUpdateStatus = this.produkts.findIndex(x => x.id === productId)
            this.produkts[productsIndexToUpdateStatus].produkt_status = updateProductData.produkt_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public statusProductType (productTypeId: number) : void {
    console.log('Change status of product type item with Id: ' + productTypeId)

    Products.ProductTypesDataService.get(productTypeId.toString())
      .then((response) => {
        this.tempProductType = response.data
        console.log(response.data)

        const updateProductTypeData = {
          produkt_type_status: !this.tempProductType.produkt_type_status
        }

        console.log('New status of product type = ' + updateProductTypeData.produkt_type_status)

        Products.ProductTypesDataService.update(productTypeId.toString(), updateProductTypeData)
          .then((response) => {
            // this.lastUpdatedProductId = response.data.id
            console.log(response.data)

            const productTypesIndexToUpdateStatus = this.produkttypes.findIndex(x => x.id === productTypeId)
            this.produkttypes[productTypesIndexToUpdateStatus].produkt_type_status = updateProductTypeData.produkt_type_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteProductWarning (productId: number) : void {
    this.deleteProductId = productId
    this.deleteProductWarningModal = true
  }

  public deleteProduct (productId: number) : void {
    console.log('Delete product item with Id: ' + productId)

    Products.ProductsDataService.delete(productId.toString())
      .then((response) => {
        console.log(response.data)

        this.retrieveProducts()
        this.deleteProductWarningModal = false
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public deleteProductTypeWarning (productTypeId: number) : void {
    this.deleteProductTypeId = productTypeId
    this.deleteProductTypeWarningModal = true
  }

  public deleteProductType (productTypeId: number) : void {
    let tempProducts: SysProduct[] = []

    if (productTypeId !== 2) { // Prevent deleting of the dummy product type
      console.log('Fetching all products with productTypeId of ' + productTypeId + ' and reassigning them to productTypeId 2')
      Products.ProductsDataService.getAll('', null, `produkt_type_id.id=${productTypeId.toString()}`)
        .then((response) => {
          tempProducts = response.data

          if (tempProducts.length > 0) {
            for (const item of tempProducts) {
              const updateProductData = {
                produkt_type_id: 2
              }

              Products.ProductsDataService.update(Number(item.id).toString(), updateProductData)
                .then((response) => {
                  console.log(response.data)
                })
                .catch((err) => {
                  this.error = err
                })
            }
          }
        })
        .catch((err) => {
          this.error = err
        })
        .then(() => {
          console.log('Delete product type item with Id: ' + productTypeId)

          Products.ProductTypesDataService.delete(productTypeId.toString())
            .then((response) => {
              console.log(response.data)

              this.retrieveProductTypes()
              this.retrieveProducts()
              this.deleteProductTypeWarningModal = false
            })
            .catch((err) => {
              this.error = err
              // console.log(err)
            })
        })
    }
  }

  public createProduct () : void {
    if (this.productData.productName.length > 1 && this.productData.productDescription.length > 1 &&
      !isNaN(Number(this.productData.productPrice)) && this.productData.productDateFrom.length > 1 &&
      this.productData.productDateTo.length > 1 && this.productTypesNameValue.value !== '0') {
      const createProductData = {
        produkt_status: this.productData.productStatus,
        produkt_navn: this.productData.productName,
        produkt_beskrivelse: this.productData.productDescription,
        produkt_pris: this.productData.productPrice,
        produkt_datofra: this.productData.productDateFrom,
        produkt_datotil: this.productData.productDateTo,
        produkt_type_id: { id: Number(this.productTypesNameValue.value) },
        user_id: 3 /* Fixme: should be the logged in user */
      }

      console.log('Attempt to create new product item with Id: ' + (1 + this.lastCreatedProductId))

      Products.ProductsDataService.create(createProductData)
        .then((response) => {
          this.lastCreatedProductId = response.data.id
          console.log(response.data)

          this.submitted = true
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
        .then(() => {
          this.$Message.success({ text: 'Produktet er blevet oprettet' })
          this.newCreateProduct()
          this.tabValue = 'Vis og rediger produkter'
        })

      // this.productData = { productStatus: false, productName: '', productDescription: '', productPrice: 0, productDateFrom: '', productDateTo: '' }
      // this.productTypesNameValue = { name: 'Vælg her', value: '0' }
    }
  }

  public newCreateProduct () : void {
    this.submitted = false
    this.productData = { productStatus: true, productName: '', productDescription: '', productPrice: 0, productDateFrom: '', productDateTo: '' }
    this.productTypesNameValue = { name: 'Vælg her', value: '0' }
    this.retrieveProducts()
  }

  public createProductType () : void {
    if (this.productTypeData.productTypeName === '' || this.productTypeData.productTypeAccountValueTo.accountId === 0) {
      this.$Message.warning({ text: 'Fejl: Et eller flere felter er ikke udfyldt korrekt' })
      return
    }
    if (this.productTypeData.productTypeAccountValue1.accountId === 0 && this.productTypeData.productTypeAccountValue2.accountId === 0 && this.productTypeData.productTypeAccountValue3.accountId === 0) {
      this.$Message.warning({ text: 'Fejl: Mindst en fra LS konto skal være valgt' })
      return
    }

    const createProductTypeData = {
      produkt_type_status: this.productTypeData.productTypeStatus,
      produkt_type_navn: this.productTypeData.productTypeName,
      produkt_type_beskrivelse: this.productTypeData.productTypeDescription,
      produkt_type_unikt: this.productTypeData.productIsUnique,
      konti_til: this.productTypeData.productTypeAccountValueTo.accountId,
      konti_fra1: (this.productTypeData.productTypeAccountValue1.accountId === 0 ? null : this.productTypeData.productTypeAccountValue1.accountId),
      konti_fra2: (this.productTypeData.productTypeAccountValue2.accountId === 0 ? null : this.productTypeData.productTypeAccountValue2.accountId),
      konti_fra3: (this.productTypeData.productTypeAccountValue3.accountId === 0 ? null : this.productTypeData.productTypeAccountValue3.accountId)
    }

    Products.ProductTypesDataService.create(createProductTypeData)
      .then((response) => {
        console.log('Create Product Type: ' + response.statusText)
        this.$Message.success({ text: 'Produktgruppen er blevet oprettet' })
        this.tabValue = 'Vis og rediger produktgrupper'
        this.clearProductDataType()
        this.generateProductTypesOptions()
        this.retrieveProductTypes()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public newCreateProductType () : void {
    this.submitted2 = false
    this.clearProductDataType()
    // this.productTypeData = { productTypeStatus: true, productIsUnique: false, productTypeName: '', productTypeDescription: '', productTypeAccountFrom1: '', productTypeAccountFrom2: '', productTypeAccountFrom3: '', productTypeAccountTo: '' }
    this.retrieveProductTypes()
    this.retrieveProducts()
  }

  public updateProduct () : boolean {
    console.log('Update information of product item with Id: ' + this.lastEditedProductId)

    if (this.productData.productName.length > 1 && this.productData.productDescription.length > 1 &&
      !isNaN(Number(this.productData.productPrice)) && this.productData.productDateFrom.length > 1 &&
      this.productData.productDateTo.length > 1 && this.productTypesNameValue.value !== '0') {
      const updateProductData = {
        produkt_status: this.productData.productStatus,
        produkt_navn: this.productData.productName,
        produkt_beskrivelse: this.productData.productDescription,
        produkt_pris: this.productData.productPrice,
        produkt_datofra: this.productData.productDateFrom,
        produkt_datotil: this.productData.productDateTo,
        produkt_type_id: { id: Number(this.productTypesNameValue.value) },
        user_id: 3 /* Fixme: should be the logged in user */
      }

      Products.ProductsDataService.update(this.lastEditedProductId.toString(), updateProductData)
        .then((response) => {
          // this.lastUpdateProductId = response.data.id
          console.log(response.data)

          this.retrieveProducts()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })

      this.editProductModal = false
      this.productData = { productStatus: true, productName: '', productDescription: '', productPrice: 0, productDateFrom: '', productDateTo: '' }
      this.productTypesNameValue = { name: 'Vælg her', value: '0' }

      return true
    }

    return false
  }

  public updateProductType () : void {
    if (this.productTypeData.productTypeName === '' || this.productTypeData.productTypeAccountValueTo.accountId === 0) {
      this.$Message.warning({ text: 'Fejl: Et eller flere felter er ikke udfyldt korrekt' })
      return
    }
    if (this.productTypeData.productTypeAccountValue1.accountId === 0 && this.productTypeData.productTypeAccountValue2.accountId === 0 && this.productTypeData.productTypeAccountValue3.accountId === 0) {
      this.$Message.warning({ text: 'Fejl: Mindst en fra LS konto skal være valgt' })
      return
    }

    const updateProductTypeData = {
      produkt_type_status: this.productTypeData.productTypeStatus,
      produkt_type_navn: this.productTypeData.productTypeName,
      produkt_type_beskrivelse: this.productTypeData.productTypeDescription,
      produkt_type_unikt: this.productTypeData.productIsUnique,
      konti_til: this.productTypeData.productTypeAccountValueTo.accountId,
      konti_fra1: (this.productTypeData.productTypeAccountValue1.accountId === 0 ? null : this.productTypeData.productTypeAccountValue1.accountId),
      konti_fra2: (this.productTypeData.productTypeAccountValue2.accountId === 0 ? null : this.productTypeData.productTypeAccountValue2.accountId),
      konti_fra3: (this.productTypeData.productTypeAccountValue3.accountId === 0 ? null : this.productTypeData.productTypeAccountValue3.accountId)
    }
    Products.ProductTypesDataService.update(this.productTypeData.productTypeId.toString(), updateProductTypeData)
      .then((response) => {
        console.log('Product Type updated: ' + response.statusText)
        this.retrieveProductTypes()
        this.generateProductTypesOptions()
        this.$Message.success({ text: 'Produktgruppe er opdateret' })
        this.editProductTypeModal = false
        this.clearProductDataType()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public generateProductTypesOptions () : void {
    let tempProductTypes: SysProductProductType[] = []

    Products.ProductTypesDataService.getAll('', null, 'produkt_type_status=true')
      .then((response) => {
        tempProductTypes = response.data

        this.productTypesNameOptions = [{ name: 'Vælg her', value: '0' }]
        for (const item of tempProductTypes) {
          this.productTypesNameOptions.push({ name: item.produkt_type_navn, value: (item.id === undefined ? '0' : Number(item.id).toString()) })
          // console.log('Product type names = ' + JSON.stringify(this.productTypesNameOptions))
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveProductTypes () : void {
    console.log('Fetching product type data from API.')

    if (this.productTypeSearchTerm.length > 0) {
      Products.ProductTypesDataService.findBySearchTerm((this.filterStatusStringValue2 === 'Aktiveret'), (this.filterStatusStringValue2 === 'Vis alt'), this.productTypeSearchTerm, this.currentProductTypeSortingOrder)
        .then((response) => {
          this.produkttypes = response.data
          // console.log(response.data)

          // this.productTypesNameOptions = [{ name: 'Vælg her', value: '0' }]
          // for (const produkttype of this.produkttypes) {
          //   this.productTypesNameOptions.push({ name: produkttype.produkt_type_navn, value: (produkttype.id === undefined ? '0' : Number(produkttype.id).toString()) })
          //   // console.log('Product type names = ' + JSON.stringify(this.productTypesNameOptions))
          // }

          this.forceRerenderProductsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue2 === 'Vis alt') {
        Products.ProductTypesDataService.getAll(this.currentProductTypeSortingOrder)
          .then((response) => {
            this.produkttypes = response.data
            console.log(response.data)

            // this.productTypesNameOptions = [{ name: 'Vælg her', value: '0' }]
            // for (const produkttype of this.produkttypes) {
            //   this.productTypesNameOptions.push({ name: produkttype.produkt_type_navn, value: (produkttype.id === undefined ? '0' : Number(produkttype.id).toString()) })
            //   console.log('Product type names = ' + JSON.stringify(this.productTypesNameOptions))
            // }

            this.forceRerenderProductsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        Products.ProductTypesDataService.findByProductTypeStatus((this.filterStatusStringValue2 === 'Aktiveret'), this.currentProductTypeSortingOrder)
          .then((response) => {
            this.produkttypes = response.data
            // console.log(response.data)

            // this.productTypesNameOptions = [{ name: 'Vælg her', value: '0' }]
            // for (const produkttype of this.produkttypes) {
            //   this.productTypesNameOptions.push({ name: produkttype.produkt_type_navn, value: (produkttype.id === undefined ? '0' : Number(produkttype.id).toString()) })
            //   console.log('Product type names = ' + JSON.stringify(this.productTypesNameOptions))
            // }

            this.forceRerenderProductsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
  }

  public parseEnum (typeString: any) : OrderStatusType {
    return <any>OrderStatusType[typeString]
  }

  // Update an existing order, currently the only things that can be altered are the comment and the status
  public updateOrder () : boolean {
    console.log('Update order information for order with id: ' + this.lastEditedOrderId)

    if (this.orderData.orderName.length > 1) {
      const updateOrderData = {
        ordre_status: OrderStatusType[this.orderData.orderStatus as keyof typeof OrderStatusType],
        ordre_kommentar: this.orderData.orderComment
      }
      Orders.OrdersDataService.update(this.lastEditedOrderId.toString(), updateOrderData)
        .then((response) => {
          console.log(response.data)

          this.$Message.success({ text: 'Ordren er blevet opdateret' })
          this.retrieveOrders()
          this.editOrderModal = false
          this.orderData = { orderCreated: '', orderUpdated: '', userName: '', orderInvoiceNumber: 0, orderComment: '', orderTransaction: 0, orderInvoice: [], orderName: '', orderAddress: '', orderCity: '', orderCountry: '', orderTelephone: '', orderEmail: '', orderTotal: 0, orderUnits: [], orderStatus: '', orderPostalNumber: '' }

          return true
        })
        .catch((err) => {
          this.error = err
        })
    }

    return false
  }

  public cancelOrderEdit () : void {
    this.orderData = { orderCreated: '', orderUpdated: '', userName: '', orderInvoiceNumber: 0, orderComment: '', orderTransaction: 0, orderInvoice: [], orderName: '', orderAddress: '', orderCity: '', orderCountry: '', orderTelephone: '', orderEmail: '', orderTotal: 0, orderUnits: [], orderStatus: '', orderPostalNumber: '' }
  }

  public editOrder (orderId: number) : void {
    console.log('Edit order with Id: ' + orderId)
    this.lastEditedOrderId = orderId

    Orders.OrdersDataService.get(orderId.toString())
      .then((response) => {
        this.tempOrder = response.data
        this.orderData = {
          orderUpdated: this.toDanishDateString(this.tempOrder.updated_at),
          orderCreated: this.toDanishDateString(this.tempOrder.created_at),
          orderInvoiceNumber: this.tempOrder.ordre_fakturanummer,
          orderComment: this.tempOrder.ordre_kommentar,
          orderStatus: this.parseEnum(this.tempOrder.ordre_status).toString(),
          orderTransaction: this.tempOrder.ordre_transaktion,
          orderInvoice: this.tempOrder.ordre_faktura,
          orderName: this.tempOrder.ordre_navn,
          orderAddress: this.tempOrder.ordre_adresse,
          orderPostalNumber: this.tempOrder.ordre_postnummer,
          orderCity: this.tempOrder.ordre_by,
          orderTelephone: this.tempOrder.ordre_telefon,
          orderCountry: this.tempOrder.ordre_land,
          orderEmail: this.tempOrder.ordre_email,
          orderUnits: this.tempOrder.ordreenheders_id,
          orderTotal: this.tempOrder.ordre_total,
          userName: (this.tempOrder.user_id === null ? '' : this.tempOrder.user_id.username)
        }
        console.log(this.orderData)
        this.editOrderModal = true

        if (this.orderData.orderUnits.length > 0) {
          let tempProductIds: number[] = []
          let tempProductParameterString = ''
          for (const item of this.orderData.orderUnits) {
            tempProductIds.push(item.produkt_id)
          }
          tempProductIds = tempProductIds.filter(function (elem, index, self) { return index === self.indexOf(elem) })

          for (const id of tempProductIds) {
            tempProductParameterString += '&id=' + id.toString()
          }
          tempProductParameterString = tempProductParameterString.substring(1)
          console.log(tempProductParameterString)

          Products.ProductsDataService.getAll('', null, tempProductParameterString)
            .then((response) => {
              this.orderProducts = response.data
              // console.log(this.orderProducts)
            })
            .catch((err) => {
              this.error = err
            })
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public getProductName (productId: number) : string {
    let retVal = '???'
    const index = this.orderProducts.findIndex(el => Number(el.id) === Number(productId))
    console.log(index)
    if (index !== -1) {
      retVal = this.orderProducts[index].produkt_navn
    }

    return retVal
  }

  public retrieveOrders () : void {
    console.log('Counting Orders')

    Orders.OrdersDataService.getCount(this.orderExtraParameter)
      .then((response) => {
        this.totalOrderPages = response.data
        this.totalNumberOfOrderPages = Math.ceil(this.totalOrderPages / this.orderPageSizedValue)
        console.log(this.currentOrderPage + ' of ' + this.totalNumberOfOrderPages + ' - total number of entries: ' + this.totalOrderPages)

        console.log('Fetching Orders from API')
        if (this.orderSearchTerm.length > 0 && this.selectedSearchField.length > 0) {
          Orders.OrdersDataService.findBySelectedSearchTerm(this.selectedSearchField, this.orderSearchTerm, this.currentOrdersSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentOrderPage - 1), pagesize: this.orderPageSizedValue, totalcount: this.totalOrderPages }, this.orderExtraParameter)
            .then((response) => {
              this.orders = response.data

              this.forceRerenderOrdersList()
              this.$forceUpdate()
            })
            .catch((err) => {
              this.error = err
            })
        } else if (this.orderSearchTerm.length > 0) {
          Orders.OrdersDataService.findBySearchTerm(this.orderSearchTerm, this.currentOrdersSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentOrderPage - 1), pagesize: this.orderPageSizedValue, totalcount: this.totalOrderPages }, this.orderExtraParameter)
            .then((response) => {
              this.orders = response.data

              this.forceRerenderOrdersList()
              this.$forceUpdate()
            })
            .catch((err) => {
              this.error = err
            })
        } else {
          Orders.OrdersDataService.getAll(this.currentOrdersSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentOrderPage - 1), pagesize: this.orderPageSizedValue, totalcount: this.totalOrderPages }, this.orderExtraParameter)
            .then((response) => {
              this.orders = response.data

              this.forceRerenderOrdersList()
              this.$forceUpdate()
            })
            .catch((err) => {
              this.error = err
            })
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveProducts () : void {
    console.log('Counting Products')
    let countFilter = ''

    if (this.filterStatusStringValue === 'Vis alt') {
      countFilter = this.productExtraParameter
    } else if (this.filterStatusStringValue === 'Aktiveret') {
      countFilter = 'produkt_status=true&' + this.productExtraParameter
    } else {
      countFilter = 'produkt_status=false&' + this.productExtraParameter
    }

    Products.ProductsDataService.getCount(countFilter)
      .then((response) => {
        this.totalProductPages = response.data
        this.totalNumberOfProductPages = Math.ceil(this.totalProductPages / this.productPageSizeValue)
        console.log(this.currentProductPage + ' of ' + this.totalNumberOfProductPages + ' - total number of entries: ' + this.totalProductPages)
        console.log('Fetching product data from API.')

        if (this.productSearchTerm.length > 0) {
          Products.ProductsDataService.findBySearchTerm((this.filterStatusStringValue === 'Aktiveret'), (this.filterStatusStringValue === 'Vis alt'), this.productSearchTerm, this.currentProductSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentProductPage - 1), pagesize: this.productPageSizeValue, totalcount: this.totalProductPages }, this.productExtraParameter)
            .then((response) => {
              this.produkts = response.data
              // console.log(response.data)

              this.forceRerenderProductsList()
              this.$forceUpdate()
            })
            .catch((err) => {
              this.error = err
              // console.log(err)
            })
        } else {
          if (this.filterStatusStringValue === 'Vis alt') {
            Products.ProductsDataService.getAll(this.currentProductSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentProductPage - 1), pagesize: this.productPageSizeValue, totalcount: this.totalProductPages }, this.productExtraParameter)
              .then((response) => {
                this.produkts = response.data
                // console.log(response.data)

                this.forceRerenderProductsList()
                this.$forceUpdate()
              })
              .catch((err) => {
                this.error = err
                // console.log(err)
              })
          } else {
            Products.ProductsDataService.findByProductStatus((this.filterStatusStringValue === 'Aktiveret'), this.currentProductSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentProductPage - 1), pagesize: this.productPageSizeValue, totalcount: this.totalProductPages }, this.productExtraParameter)
              .then((response) => {
                this.produkts = response.data
                // console.log(response.data)

                this.forceRerenderProductsList()
                this.$forceUpdate()
              })
              .catch((err) => {
                this.error = err
                // console.log(err)
              })
          }
        }
      })
      .catch((err) => {
        this.error = err
      })
  }

  // Accounts Methods

  @Watch('accountLSFilterValue')
  onAccountFilterValueChange (newVal: string) : void {
    if (newVal === 'Alle') {
      this.accountExtraParameter = ''
    } else {
      this.accountExtraParameter = `konto_ls=${newVal}`
    }
    console.log(this.accountExtraParameter)
    this.retrieveAccounts()
  }

  @Watch('accountPageSizeValueString')
  onAccountPageSizeValueChange (newVal: string) : void {
    this.accountPageSizeValue = Number(newVal)
    this.currentAccountPage = 1
    this.retrieveAccounts()
    CommonFunctions.scrollToTop()
  }

  public findAccount () : void {
    this.accountSearchTerm = this.accountInputValue
    this.retrieveAccounts()
  }

  public async retrieveAccounts () : Promise<void> {
    try {
      this.totalAccountPages = (await Products.AccountsDataService.getCount(this.accountExtraParameter)).data
    } catch (err) {
      this.error = err
    }
    this.totalNumberOfAccountPages = Math.ceil(this.totalAccountPages / this.accountPageSizeValue)

    if (this.accountSearchTerm.length > 0) {
      this.currentAccountPage = 1
      Products.AccountsDataService.findBySearchTerm(this.accountSearchTerm, this.currentAccountsSort, { slicemode: 1, start: 0, limit: this.accountPageSizeValue, page: (this.currentAccountPage - 1), pagesize: this.accountPageSizeValue, totalcount: this.totalAccountPages }, this.accountExtraParameter)
        .then((response) => {
          this.accounts = response.data
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      Products.AccountsDataService.getAll(this.currentAccountsSort, { slicemode: 1, start: 0, limit: this.accountPageSizeValue, page: (this.currentAccountPage - 1), pagesize: this.accountPageSizeValue, totalcount: this.totalAccountPages }, this.accountExtraParameter)
        .then((response) => {
          this.accounts = response.data
          console.log(this.accounts)

          console.log(this.accountLSFilterOptions)
        })
        .catch((err) => {
          this.error = err
        })
    }
    if (this.accountLSFilterOptions.indexOf('Alle') === -1) {
      this.accountLSFilterOptions.unshift('Alle')
    }
  }

  public accountSort (sortBy = '') : void {
    switch (sortBy) {
      case 'accountName':
        if (this.oldAccountSort !== sortBy) {
          this.currentAccountsSort = 'konti_navn:desc'
          this.oldAccountSort = sortBy
        } else {
          this.currentAccountsSort = 'konti_navn:asc'
          this.oldAccountSort = ''
        }
        break
      case 'accountNumber':
        if (this.oldAccountSort !== sortBy) {
          this.currentAccountsSort = 'konti_nummer:asc'
          this.oldAccountSort = sortBy
        } else {
          this.currentAccountsSort = 'konti_nummer:desc'
          this.oldAccountSort = ''
        }
        break
      case 'accountLS':
        if (this.oldAccountSort !== sortBy) {
          this.currentAccountsSort = 'konto_ls:asc'
          this.oldAccountSort = sortBy
        } else {
          this.currentAccountsSort = 'konto_ls:desc'
          this.oldAccountSort = ''
        }
        break
      default:
        this.oldAccountSort = ''
        this.currentAccountsSort = 'konti_navn:asc'
        this.accountInputValue = ''
        this.accountSearchTerm = ''
        this.currentAccountPage = 1
        break
    }
    this.retrieveAccounts()
  }

  public jumpToAccountPage (pageNumber: number) : void {
    this.currentAccountPage = pageNumber
    if (this.currentAccountPage < 1) {
      this.currentAccountPage = 1
    } else if (this.currentAccountPage > this.totalNumberOfAccountPages) {
      this.currentAccountPage = this.totalNumberOfAccountPages
    }

    this.retrieveAccounts()
    // CommonFunctions.scrollToTop()
  }

  public turnToNewAccountPage (pageChange: number) : void {
    this.currentAccountPage += pageChange
    if (this.currentAccountPage < 1) {
      this.currentAccountPage = 1
    } else if (this.currentAccountPage >= this.totalNumberOfAccountPages) {
      this.currentAccountPage = this.totalNumberOfAccountPages
    }

    this.retrieveAccounts()
    // CommonFunctions.scrollToTop()
  }

  public newAccount () : void {
    this.account = { id: null, konti_navn: '', konti_nummer: 0, konto_ls: AccountLSType.ls1, updated_at: '', created_at: '' }
    this.creatingNewAccount = true
    this.accountModal = true
  }

  public createNewAccount () : void {
    if (this.account.konti_navn.length === 0 || this.account.konti_nummer.toString() === '') {
      return
    }
    const newAccount = {
      konti_navn: this.account.konti_navn,
      konti_nummer: this.account.konti_nummer,
      konto_ls: this.account.konto_ls
    }

    Products.AccountsDataService.create(newAccount)
      .then((response) => {
        console.log('New Account Created: ' + response.statusText)
        this.$Message.success({ text: 'Konto oprettet' })
        this.accountModal = false
        this.retrieveAccounts()
        this.retrieveAccountsOptions()
      })
      .catch((err) => {
        this.$Message.waring({ text: 'Fejl: Noget gik galt\nFejlkode: ' + err })
        this.error = err
      })
  }

  public editAccount (accountId: number) : void {
    Products.AccountsDataService.get(accountId.toString())
      .then((response) => {
        this.account = response.data as SysAccount
        this.accountModal = true
      })
      .catch((err) => {
        this.error = err
      })
  }

  public updateAccount () : void {
    if (this.account.konti_navn.length === 0 || this.account.konti_nummer.toString() === '') {
      return
    }

    const updateAccount = {
      konti_navn: this.account.konti_navn,
      konti_nummer: this.account.konti_nummer,
      konto_ls: this.account.konto_ls
    }

    Products.AccountsDataService.update(Number(this.account.id).toString(), updateAccount)
      .then((response) => {
        console.log('Account Updated: ' + response.statusText)
        this.$Message.success({ text: 'Konto er opdateret' })
        this.retrieveAccounts()
        this.retrieveAccountsOptions()
        this.accountModal = false
      })
      .catch((err) => {
        this.$Message.waring({ text: 'Fejl: Noget gik galt\nFejlkode: ' + err })
        this.error = err
      })
  }

  public deleteAccountWarning (accountId: number) : void {
    this.accountDeleteId = accountId
    this.accountWarningModal = true
  }

  public deleteAccount () : void {
    Products.AccountsDataService.delete(this.accountDeleteId.toString())
      .then((response) => {
        console.log('Account Deleted: ' + response.statusText)
        this.$Message.success({ text: 'Konto slettet' })
        this.accountWarningModal = false
        this.retrieveAccounts()
        this.retrieveAccountsOptions()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveAccountsOptions () : void {
    if (this.productTypeAccountOptions.length === 0) {
      Products.AccountsDataService.getAll('konti_navn:asc', null, '')
        .then((response) => {
          this.tempAccounts = response.data as SysAccount[]

          for (const account of this.tempAccounts) {
            this.productTypeAccountOptions.push({
              accountId: Number(account.id),
              accountName: account.konti_navn,
              ls: account.konto_ls
            })
          }
          this.productTypeAccountOptions.unshift({
            accountId: 0,
            accountName: 'Vælg konto',
            ls: null
          })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public clearProductDataType () : void {
    this.productTypeData = { productTypeId: 0, productTypeStatus: true, productIsUnique: false, productTypeName: '', productTypeDescription: '', productTypeAccountFrom1: '', productTypeAccountFrom2: '', productTypeAccountFrom3: '', productTypeAccountTo: '', productTypeAccountValue1: { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null }, productTypeAccountValue2: { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null }, productTypeAccountValue3: { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null }, productTypeAccountValueTo: { accountId: 0, accountNumber: 0, accountName: 'Vælg konto', ls: null } }
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveProductTypes()
        this.retrieveProducts()
        this.generateProductTypesOptions()
        this.retrieveOrders()
        this.retrieveAccounts()
        this.retrieveAccountsOptions()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
